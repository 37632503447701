.preloader {
    @include flex;
    background-attachment: fixed;
    background-color: $black;
    background-image: url("../../img/header_bg.png");
    background-size: cover;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99999;
    @include respond-to(sm) {
        background-attachment: unset;
    }

    &__svg {
        height: 80px;
        visibility: hidden;
        width: 460px;
        @include respond-to(sm) {
            height: 40px;
            width: 230px;
        }
    }
}