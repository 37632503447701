#button-hamburger {
    display: none;
    @include respond-to(xs) {
        cursor: pointer;
        display: block;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 40px;
        top: -2px;
        width: 30px;
        z-index: 9;
    }

    span {
        background-color: $white;
        border-radius: 2px;
        display: block;
        height: 3px;
        width: 30px;
    }

    span:nth-child(1) {
        animation: outT 0.6s backwards;
        animation-direction: reverse;
    }

    span:nth-child(2) {
        margin: 6px 0;
        animation: outM 0.6s backwards;
        animation-direction: reverse;
    }

    span:nth-child(3) {
        animation: outBtm 0.6s backwards;
        animation-direction: reverse;
    }

    &.open {
        span:nth-child(1) {
            animation: inT 0.6s forwards;
        }

        span:nth-child(2) {
            animation: inM 0.6s forwards;
        }

        span:nth-child(3) {
            animation: inBtm 0.6s forwards;
        }
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

// responsive menu
#navbar-menu {
    @include respond-to(xs) {
        background: $black;
        left: 0;
        height: 0;
        opacity: 0;
        padding: 85px 0 50px;
        position: absolute;
        top: -25px;
        visibility: hidden;
        width: 100%;
    }

    &.show {
        height: 290px;
        opacity: 1;
        visibility: visible;
    }
}

.header {
    &__list {
        @include respond-to(xs) {
            align-items: flex-end;
            flex-direction: column;
            padding-right: 25px;
            padding-top: 25px;
            width: 100%;
        }
    }

    &__item {
        @include respond-to(xs) {
            align-items: flex-end;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
}