.form {
    background: $gray;
    width: 100%;

    &__wrapper {
        padding: 128px 60px;
        @include respond-to(lg) {
            padding: 50px 45px 70px;
        }
        @include respond-to(sm) {
            padding: 45px 30px;
        }
    }

    &__title {
        color: $text;
        font-size: 22px;
        line-height: 30px;
        @include respond-to(lg) {
            font-size: 20px;
        }
    }

    &__subtitle {
        color: $dark_gray;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 30px;
        @include respond-to(lg) {
            font-size: 14px;
            margin-bottom: 45px;
        }
    }
}

// contact form

.form__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

input {
    background: $white;
    border: 0;
    color: $placeholder_hover;
    height: 55px;
    font-size: 14px;
    margin-bottom: 30px;
    padding: 0 25px;
    width: 100%;
    @include events {
        outline: 0;
    }
}

textarea {
    border: 0;
    color: $placeholder_hover;
    height: 170px;
    font-size: 14px;
    margin-bottom: 30px;
    padding: 30px 25px;
    width: 100%;
    @include events {
        outline: 0;
    }
}

input[type="submit"] {
    @include flex;
    @include transition;
    background-color: $red;
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;
    box-shadow: 0 3px 60px 3px rgba(0, 0, 0, 0.19);
    color: $white;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 0;
    width: 180px;
    @include events {
        box-shadow: 0 3px 44px 2px rgba(0, 0, 0, 0.44);
        background-color: $burgundy;
        color: $white;
        text-decoration: none;
    }

    &.spinner {
        background-image: url(../../img/spinner.svg);
        pointer-events: none;
        cursor: default;
    }

    &.send {
        @include transition(all, .7s);
        background: green;
        pointer-events: none;
        cursor: default;
    }
}

::-webkit-input-placeholder {
    color: $placeholder;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
}

::-moz-placeholder {
    color: $placeholder;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
}

:-ms-input-placeholder {
    color: $placeholder;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
}

:-moz-placeholder {
    color: $placeholder;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
}
