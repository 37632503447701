.contact {
    &__wrapper {
        padding-top: 125px;
        padding-left: 50px;
        @include respond-to(lg) {
            padding: 50px 35px 0 50px;
        }
        @include respond-to(sm) {
            padding: 45px 30px;
        }
    }

    &__subtitle {
        color: $dark_gray;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 70px;
        @include respond-to(lg) {
            font-size: 14px;
            margin-top: 45px;
        }
    }

    &__list {
        font-size: 16px;
        font-weight: 300;
        list-style-type: none;
        margin: 50px 0;
        padding-left: 0;

        img {
            height: 22px;
            margin-right: 20px;
            width: 22px;
        }
    }

    &__list-item {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        a {
            @include transition;
            color: $text;
            text-decoration: none;
            @include events {
                color: $burgundy
            }
        }

        @include respond-to(lg) {
            font-size: 14px;
        }
    }

    &__additional-info {
        font-weight: 300;
        margin-bottom: 10px;
        @include respond-to(lg) {
            font-size: 14px;
        }
    }
}

// reset container on mobile
.contact-container {
    @include respond-to(lg) {
        max-width: 100%;
    }
}