.hero {
    background-attachment: fixed;
    background-color: black;
    background-image: url('../../img/header_bg.png');
    background-size: cover;
    height: 100vh;
    min-height: 750px;
    overflow: hidden;
    width: 100%;
    @include respond-to(md) {
        height: 768px;
    }
    @include respond-to(sm) {
        background-attachment: unset;
        height: 100vh;
        min-height: 600px;
    }

    > div {
        align-items: center;
        display: flex;
        height: 100%;
    }

    &__wrapper {
        margin-left: 35px;
        margin-top: 135px;
        @include respond-to(lg) {
            margin-left: 10px;
        }
        @include respond-to(md) {
            margin-left: 15px;
            margin-top: 75px;
        }

        @include respond-to(sm) {
            margin-left: 0;
        }
        @include respond-to(xs) {
            margin-left: 15px;
        }
    }

    &__title {
        color: $white;
        font-family: $rubik;
        font-size: 50px;
        font-weight: bold;
        line-height: 1.3;
        width: 560px;
        @include respond-to(lg) {
            font-size: 40px;
        }
        @include respond-to(sm) {
            font-size: 30px;
            padding-right: 60px;
            width: 100%;
        }
    }

    &__button {
        @include flex;
        @include transition;
        background: $red;
        border: 0;
        box-shadow: 0 3px 60px 3px rgba(0, 0, 0, 0.19);
        color: $white;
        font-size: 18px;
        height: 55px;
        margin-top: 60px;
        width: 265px;
        @include respond-to(lg) {
            font-size: 14px;
            height: 51px;
            width: 210px;
        }
        @include respond-to(sm) {
            font-size: 12px;
            height: 41px;
            width: 170px;
        }

        @include events {
            box-shadow: 0 3px 44px 2px rgba(0, 0, 0, 0.44);
            background: $burgundy;
            color: $white;
            text-decoration: none;
        }
    }

    &__image {
        bottom: -150px;
        position: absolute;
        right: -60px;
        @include respond-to(md) {
            bottom: -80px;
            right: -75px;
            width: 350px;
        }
        @include respond-to(sm) {
            bottom: -50px;
            right: -15px;
            width: 150px;
        }
    }
}