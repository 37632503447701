.footer {
    @include flex;
    background: $black;
    height: 100px;

    span {
        color: $white;
        font-size: 16px;
        font-weight: 300;
        @include respond-to(sm){
            font-size: 12px;
            text-align: center;
        }
    }
}