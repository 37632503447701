.offer {

    // background color
    &__wrapper {
        background-color: $gray;
        padding: 0;

        &:nth-child(2) {
            background-color: $white;
            @include respond-to(sm){
                background-color: $gray;
            }
        }

        &:nth-child(3) {
            @include respond-to(md) {
                background-color: $gray;
            }
        }

        &:nth-child(4) {
            @include respond-to(md) {
                background-color: $white;
            }
        }

        &:nth-child(5) {
            background-color: $white;
            @include respond-to(md) {
                background-color: $gray;
            }
            @include respond-to(sm) {
                background-color: $white;
            }
        }

        &:nth-child(6) {
            @include respond-to(md) {
                background-color: $white;
            }
            @include respond-to(sm){
                background-color: $gray;
            }
        }

        &:nth-child(7) {
            background: $white;
            @include respond-to(md) {
                background-color: $gray;
            }
        }
    }

    &__box {
        height: 290px;
        overflow: hidden;
        padding: 90px 55px 0 50px;
        width: 100%;
        @include respond-to(lg) {
            height: 255px;
        }
        @include respond-to(sm) {
            height: 180px;
            padding: 55px 25px 0 30px;
        }

        &--image {
            padding: 0;
            position: relative;

            &:before {
                background-color: $black;
                content: '';
                height: 100%;
                left: 0;
                opacity: .7;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .section__title {
                left: 50px;
                position: absolute;
                top: 55px;
                @include respond-to(lg) {
                    left: 55px;
                }
                @include respond-to(sm){
                    left: 30px;
                    top: 25px;
                }
            }
        }

        img {
            @include objectfit;
        }
    }

    &__text {
        color: $text;
        font-size: 21px;
        font-weight: 500;
        line-height: 1.4;
        position: relative;
        @include respond-to(lg) {
            font-size: 19px;
        }
        @include respond-to(sm) {
            font-size: 15px;
        }

        &:before {
            background-color: $red;
            content: '';
            height: 5px;
            left: 0;
            position: absolute;
            top: -30px;
            width: 50px;
            @include respond-to(sm) {
                height: 4px;
                top: -20px;
                width: 35px;
            }
        }
    }
}

// reset container on mobile
.offer-container {
    @include respond-to(lg) {
        max-width: 100%;
    }
}