.section {
    &__title {
        color: $white;
        font-size: 42px;
        font-weight: 500;
        @include respond-to(lg) {
            font-size: 38px;
        }
        @include respond-to(sm) {
            font-size: 32px;
        }

        &--black {
            color: $black;
        }
    }
}
