// object-fit fix - please check the file script.js
.custom-object-fit {
    position: relative;
    background-size: cover;
    background-position: center center;
    img {
        opacity: 0;
    }
}

body {
    overflow-x: hidden;
    position: relative;
}

* {
    font-family: $rubik;
}

figure{
    margin: 0;
}

.padding-reset {
    padding: 0;
}