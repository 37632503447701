.header {
    margin-top: 60px;
    position: absolute;
    width: 100%;
    z-index: 9;
    @include respond-to(lg) {
        margin-top: 35px;
    }
    @include respond-to(sm) {
        margin-top: 20px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        @include respond-to(lg) {
            align-items: center;
        }
        @include respond-to(md) {
            padding: 0 25px;
        }
    }

    &__logo {
        &--desktop {
            @include respond-to(sm) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            @include respond-to(sm) {
                display: flex;
                position: relative;
                z-index: 9;
            }

            .svg-logo--white {
                height: 25px;
                width: 138px;
            }
        }
    }

    &__list {
        display: flex;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    &__item {
        margin-left: 45px;
    }

    &__link {
        @include transition;
        color: $white;
        font-family: $worksans;
        font-size: 18px;
        @include events {
            color: $red;
            text-decoration: none;
        }
    }
}

// logo
.svg-logo--white {
    height: 95px;
    width: 190px;
    @include respond-to(lg) {
        width: 155px;
        height: 55px;
    }

    path {
        fill: $white;

        &.st0 {
            fill: $red;
        }
    }
}