// colors
$black: #100f0f;
$text: #434343;
$red: #b12121;
$burgundy: #901717;
$white: #ffffff;
$gray: #f5f5f5;
$dark_gray: #5c5c5c;
$placeholder: #8c8c8c;
$placeholder_hover: #5b5b5b;

$rubik : 'Rubik', sans-serif;
$worksans: 'Work Sans', sans-serif;

//responsive
$breakpoints: (
  'xs': (max-width: 575px),
  'sm': (max-width: 767px),
  'md': (max-width: 991px),
  'lg': (max-width: 1199px),
  'xl': (min-width: 1200px),
  'hxs': (max-height: 400px),
  'hsm': (max-height: 599px),
  'hmd': (max-height: 699px),
  'hlg': (max-height: 768px),
  'hxl': (min-height: 769px)
);
