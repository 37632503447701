.about {
    margin: 0;
    &__wrapper {
        padding: 0;
    }

    &__image {
        height: 100%;
        overflow: hidden;
        width: 100%;
        @include respond-to(md){
            height: 540px;
        }
        @include respond-to(sm){
            height: 280px;
        }

        img {
            @include objectfit;
        }
    }

    &__text {
        background: $red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 75px 0 75px 60px;
        @include respond-to(lg) {
            padding: 55px 0 55px 50px;
        }
        @include respond-to(md) {
            padding: 55px 50px;
        }
        @include respond-to(sm){
            padding: 45px 30px;
        }

        .section__title {
            margin-bottom: 60px;
            @include respond-to(lg) {
                margin-bottom: 45px;
            }
        }

        p {
            color: $white;
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            margin-bottom: 0;
            max-width: 500px;

            &:first-of-type {
                margin-bottom: 40px;
            }

            @include respond-to(lg) {
                font-size: 14px;
                max-width: 400px;
            }
            @include respond-to(md) {
                max-width: 100%;
            }
        }

        strong {
            font-weight: 600;
        }
    }
}